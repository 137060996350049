import { render, staticRenderFns } from "./assessmentIndex.vue?vue&type=template&id=4ce8d706&scoped=true"
import script from "./assessmentIndex.vue?vue&type=script&lang=js"
export * from "./assessmentIndex.vue?vue&type=script&lang=js"
import style0 from "./assessmentIndex.vue?vue&type=style&index=0&id=4ce8d706&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce8d706",
  null
  
)

component.options.__file = "assessmentIndex.vue"
export default component.exports