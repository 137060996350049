<template>
    <div class="userManage bgblue">
        <!-- 客户数量、待结安装费等 -->
        <div class="manage">
            <el-row :gutter="7">
                <el-col style="width: 20%;">
                    <el-card shadow="hover" class="cardUser">
                        <div slot="header" class="cardUserItem">
                            <div style="flex: 0.5">
                                <img style="width: 60px;" src="@/assets/images/agent/e1.png" />
                            </div>
                            <div>
                                <div style="font-size: 20px; font-weight: bold;">合伙人</div>
                                <div class="cardUserItemshu">
                                    <div style="flex: 1">
                                        <div class="commonUser">待处理</div>
                                        <div class="commonUser">{{ this.dypeList[0].待处理 }}</div>
                                    </div>
                                    <div style="flex: 1; margin-left: 20px; text-align: center;">
                                        <div>
                                            <div class="commonUser">总数量</div>
                                            <div class="commonUser">{{ this.dypeList[0].总计 }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <div style="display: flex;">
                                <div style="color: #D7DFE9; margin-right: 20px;">今日新增</div>
                                <div style="margin-right: 20px;">{{ this.dypeList[0].今日新增 }}</div>
                            </div>
                            <el-link type="primary" :underline="false">详情</el-link>
                        </div>
                    </el-card>
                </el-col>
                <el-col style="width: 20%;">
                    <el-card shadow="hover" class="cardUser">
                        <div slot="header" class="cardUserItem">
                            <div style="flex: 0.5">
                                <img style="width: 60px;" src="@/assets/images/agent/e2.png" />
                            </div>
                            <div>
                                <div style="font-size: 20px; font-weight: bold;">分销商</div>
                                <div class="cardUserItemshu">
                                    <div style="flex: 1">
                                        <div class="commonUser">待处理</div>
                                        <div class="commonUser">{{ this.dypeList[1].待处理 }}</div>
                                    </div>
                                    <div style="flex: 1; margin-left: 20px; text-align: center;">
                                        <div>
                                            <div class="commonUser">总数量</div>
                                            <div class="commonUser">{{ this.dypeList[1].总计 }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <div style="display: flex;">
                                <div style="color: #D7DFE9; margin-right: 20px;">今日新增</div>
                                <div style="margin-right: 20px;">{{ this.dypeList[1].今日新增 }}</div>
                            </div>
                            <el-link type="primary" :underline="false">详情</el-link>
                        </div>
                    </el-card>
                </el-col>
                <el-col style="width: 20%;">
                    <el-card shadow="hover" class="cardUser">
                        <div slot="header" class="cardUserItem">
                            <div style="flex: 0.5">
                                <img style="width: 60px;" src="@/assets/images/agent/e3.png" />
                            </div>
                            <div>
                                <div style="font-size: 20px; font-weight: bold;">安装商</div>
                                <div class="cardUserItemshu">
                                    <div style="flex: 1">
                                        <div class="commonUser">待处理</div>
                                        <div class="commonUser">{{ this.dypeList[2].待处理 }}</div>
                                    </div>
                                    <div style="flex: 1; margin-left: 20px; text-align: center;">
                                        <div>
                                            <div class="commonUser">总数量</div>
                                            <div class="commonUser">{{ this.dypeList[2].总计 }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <div style="display: flex;">
                                <div style="color: #D7DFE9; margin-right: 20px;">今日新增</div>
                                <div style="margin-right: 20px;">{{ this.dypeList[2].今日新增 }}</div>
                            </div>
                            <el-link type="primary" :underline="false">详情</el-link>
                        </div>
                    </el-card>
                </el-col>

                <el-col style="width: 20%;">
                    <el-card shadow="hover" class="cardUser">
                        <div slot="header" class="cardUserItem">
                            <div style="flex: 0.5">
                                <img style="width: 60px;" src="@/assets/images/agent/e4.png" />
                            </div>
                            <div>
                                <div style="font-size: 20px; font-weight: bold;">网络经理</div>
                                <div class="cardUserItemshu">
                                    <div style="flex: 1">
                                        <div class="commonUser">待处理</div>
                                        <div class="commonUser">{{ this.dypeList[3].待处理 }}</div>
                                    </div>
                                    <div style="flex: 1; margin-left: 20px; text-align: center;">
                                        <div>
                                            <div class="commonUser">总数量</div>
                                            <div class="commonUser">{{ this.dypeList[3].总计 }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <div style="display: flex;">
                                <div style="color: #D7DFE9; margin-right: 20px;">今日新增</div>
                                <div style="margin-right: 20px;">{{ this.dypeList[3].今日新增 }}</div>
                            </div>
                            <el-link type="primary" :underline="false">详情</el-link>
                        </div>
                    </el-card>
                </el-col>
                <el-col style="width: 20%;">
                    <el-card shadow="hover" class="cardUser">
                        <div slot="header" class="cardUserItem">
                            <div style="flex: 0.5">
                                <img style="width: 60px;" src="@/assets/images/agent/e5.png" />
                            </div>
                            <div>
                                <div style="font-size: 20px; font-weight: bold;">合伙人总数</div>
                                <div class="cardUserItemshu">
                                    <div style="flex: 1">
                                        <div class="commonUser">待处理</div>
                                        <div class="commonUser">{{ dypeZList.待处理 }}</div>
                                    </div>
                                    <div style="flex: 1; margin-left: 20px; text-align: center;">
                                        <div>
                                            <div class="commonUser">总数量</div>
                                            <div class="commonUser">{{ dypeZList.总计 }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                            <div>今日新增&nbsp;&nbsp;{{ dypeZList.今日新增 }}</div>
                            <el-link type="primary" :underline="false">详情</el-link>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <!-- 快捷入口 -->
        <div class="uickAccess">
            <el-card shadow="never" class="uickAccessCard">
                <div slot="header">
                    <el-row>
                        <el-col :span="12">
                            <div>人数</div>
                        </el-col>
                        <el-col :span="6">
                            <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;" @input="changeRadio()">
                                <el-radio-button label="1">今日</el-radio-button>
                                <el-radio-button label="2">本月</el-radio-button>
                                <el-radio-button label="3">今年</el-radio-button>
                                <el-radio-button label="4">自定义</el-radio-button>
                            </el-radio-group>
                        </el-col>
                        <el-col :span="6">
                            <el-date-picker :disabled="tabPosition != '4'" v-model="allDate" type="daterange"
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <div id="chart" style="width: 100%; height: 400px;"></div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            activeName: "first",
            radio1: '',
            allDate: '',
            quickAccessList: [
                "客户列表",
                "代理商列表",
                "慧分享",
                "备忘录",
                "质量管理",
            ],
            tabPosition: '1',
            dypeList: [],
            dypeZList: [],
            timeInquireList: [],

        };
    },
    created() {
        this.getDype()
        this.getDypeZ()
        this.getTimeInquire()
    },
    mounted() {
        setTimeout(() => {
            this.drawChart()
        }, 1000);
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        // 折线图方法
        drawChart() {
            // 创建一个新的Echarts实例
            const chart = echarts.init(document.getElementById('chart'));
            // 设置选项
            let option = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['1日', '2日', '3日', '4日', '5日', '6日', '7日', '8日', '9日', '10日', '11日', '12日', '13日', '14日', '15日', '16日', '17日', '18日', '19日', '20日', '21日', '22日', '23日', '24日', '25日', '26日', '27日', '28日', '29日', '30日', '31日']
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: 1,
                    interval: 0.2
                },
                series: [
                    {
                        data: [0, 1, 0.4, 0.6, 0.8, 1],
                        type: 'line',
                        showSymbol: false, // 隐藏数据点标记
                    }
                ]
            };
            option && chart.setOption(option);
        },
        changeRadio() {
            if (this.tabPosition != '4') {
                this.allDate = ''
                let data = {}
                data.dayandmonth = this.tabPosition
                this.getTimeInquire(data)
            } else {
            }
        },
        // 合伙人、分销商...
        getDype() {
            this.$http.post("/houseAssessmentTasks/Dype", {}).then(res => {
                if (res.data.code == 200) {
                    this.dypeList = res.data.data
                }
            });
        },
        // 合伙人总数
        getDypeZ() {
            this.$http.post("/houseAssessmentTasks/DypeZ", {}).then(res => {
                if (res.data.code == 200) {
                    this.dypeZList = res.data.data
                }
            });
        },
        // 图
        getTimeInquire(data) {
            this.$http.post("/houseAssessmentTasks/TimeInquire", data).then(res => {
                if (res.data.code == 200) {
                    this.timeInquireList = res.data.data
                }
            });
        },

    },
};
</script>

<style lang="less" scoped>
.userManage {
    padding: 7px;
    // background-color: #F5F7F9;
    .manage {

        .cardUser {
            height: 170px;
            border-radius: 10px;

            .cardUserItem {
                display: flex;

                .cardUserItemshu {
                    display: flex;
                    text-align: center;
                }

                .commonUser {
                    font-size: 14px;
                    margin-top: 12px;
                    color: #757575;
                }
            }
        }
    }

    .uickAccess {
        .uickAccessCard {
            margin-top: 7px;
            width: 100%;
            border-radius: 10px;

            .uickAccessItem {
                // height: 30px;
                margin: 10px 10px;
                width: 20%;
                background-color: #E8F3FC;
                border-radius: 10px;
            }
        }
    }

    .statisticalChart {
        margin-top: 20px;
        width: 100%;
        display: flex;

        .salesStatement {
            position: relative;
            border-radius: 10px;
            flex: 1.2;

            .dateYue {
                display: flex;
                position: absolute;
                top: 40px;
                right: 50px;
            }
        }

        /deep/ .el-card__header{
            padding: 20px 18px 0 18px !important;
        }
        .customerStatistics {
            margin-left: 10px;
            border-radius: 10px;
            flex: 0.8;
        }
    }

}
</style>